<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="sm" type="search" v-model="booking_no" placeholder="Booking No."/>
        </CCol>
        
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            
            placeholder="Customer"
          > 
          
          </v-select> 
        </CCol> 
       
         
        
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="destinationList.data"
            :reduce="item => item.id"
            v-model="destination_id"
            placeholder="Destination"
          > 
          </v-select>
        </CCol>
         <CCol lg="1">
          
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
       
      <CRow>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery Date from" />
          
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery Date to" />
        </CCol>
       
        
        <CCol lg="4">
          
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import moment from 'moment';
   import Datepicker from 'vuejs-datepicker';
   import Swal from 'sweetalert2';
    export default {
        name: "OpexReportSearch",
        data() {
          return {
            booking_no: null,
            customer_id: null,
            destination_id : null,
            delivery_date_to: moment(new Date())._d,
            delivery_date_from: moment(new Date()).subtract(1, 'weeks')._d, 
            customerList: {
              data: []
            },
            destinationList: {
              data: []
            }
          }
        },
      mounted() {
       
        this.getCustomer();
        this.getDestinationList()
        
      },
      components: {vSelect,Datepicker},
      methods: {
        getDestinationList(){

          axios.get(config.api_path+'/reference/settings-list',{
			  	params:{
					setting_type:'destination'
					}
						})
				.then(response => {
					this.destinationList = response.data; 
				})

        },
        search() {
          
          if(Math.ceil(moment(this.delivery_date_to).diff(moment(this.delivery_date_from), 'weeks', true).toFixed(1)) > 1){
            Swal.fire({
              title: 'Note!',
              text: "Delivery range should not exceed 1 week.",
              icon: 'warning', 
            })
            return;
          }
         
          const data = {
            booking_no : this.booking_no,
            customer_id : this.customer_id,
            destination_id : this.destination_id,
            delivery_date_from : this.delivery_date_from ? moment(this.delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000 : null,
            delivery_date_to : this.delivery_date_to ? moment(this.delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000 : null
          }
          this.$emit("opex-search-query", data)
        },
        clearSearch() {
          const data = {
            booking_no : null,
            customer_id : null,
            destination_id : null,
            delivery_date_to: moment(new Date())._d,
            delivery_date_from: moment(new Date()).subtract(1, 'weeks')._d, 
          }
          this.$emit("opex-search-query", data)
        },

        getCustomer(){
           axios.get(config.api_path+'/reference/customer-list', {
            params: {
              show_all : true
            }
          })
	    	.then(response => {
	    		this.customerList = response.data; 
	    	})

      },
   

      
      }
    }
</script>

<style scoped>

</style>
